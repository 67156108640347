<template>
  <div>
    <header class="overflow-hidden mb-4 rounded-t-0" style="border-radius:46px">
        <v-img
        class="white--text align-end"
        height="250px"
        :lazy-src="require('@/assets/images/placeholder.png')"
        :src="clinic.coverPic ? clinic.coverPic : require('@/assets/images/placeholder.png')"
      >
        <v-avatar
            class="mb-2 ms-2 me-0"
            color="grey"
            size="92"
          >
            <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="clinic.profilePic ? clinic.profilePic : require('@/assets/images/placeholder.png')" class="border border-white border-4"></v-img>
        </v-avatar>
      </v-img>
    </header>
    <v-container fluid>
      <span class="d-block f18 font-weight-bold">{{clinic.name}}</span>
      <p class="f14 my-2" v-html="clinic.about"></p>
      <a class="d-block f15 primary--text mt-2 ltr" :href="clinic.link" target="_blank">{{clinic.link}}</a>
      <v-btn color="green" block class="mt-4 text-center f15 white--text rounded-lg" @click="openCallBottomSheet()">
          {{ $t('Call') }}
      </v-btn>
    </v-container>
    <v-container fluid>
      <div class="d-flex justify-content-between mt-4" v-if="questions && questions.length > 0">
        <div>
          <span class="16 font-weight-bold">{{
              $t("Answered questions")
          }}</span>
        </div>
        <div>
          <v-btn color="primary" depressed plain link min-width="auto" min-height="auto" height="auto" class="primary--text pa-1" :to="`/s/clinic/${$route.params.clinicId}/questions`">
            {{ $t('Show all') }}
          </v-btn>
        </div>
      </div>

      <div class="mt-2" v-if="questions && questions.length > 0">
        <v-carousel height="auto" cycle hide-delimiter-background :show-arrows="false" :interval="4000" light>
          <v-carousel-item v-for="(item, i) in questions" :key="i">
            <v-card class="mx-2 mt-2 mb-12 d-flex flex-column justify-space-between border" elevation="0" hover ripple :to="`/s/questions/${item.id}`">
              <v-card-title class="d-flex justify-space-between align-items-center">
                <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{ item.answers.length }}
                  {{ $t("Answer") }}</span>
                <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{ $t("Answer") }}</span>
                <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
              </v-card-title>

              <v-card-text>
                <p class="f14 text-justify" v-html="item.question"></p>
              </v-card-text>
              
              <v-divider dark class="m-0"></v-divider>

              <v-card-actions class="d-flex justify-space-between pt-2 align-center">
                <div v-if="item.answers && item.answers.length">
                  <div class="d-flex flex-row align-items-center">
                    <section class="d-flex flex-row avatar-group right-to-left">
                      <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                        class="avatars-group__item avatar">
                        <v-avatar size="36" color="primary" class="bordered">
                          <img :src="answer.doctorProfilePic" :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                            v-if="answer.doctorProfilePic && index < 2" />

                          <span v-else-if="index < 2" class="white--text f12">
                            {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                          </span>

                          <span v-else class="white--text f14">
                            ...
                          </span>
                        </v-avatar>
                      </span>
                    </section>
                    <div>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                        {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                      </span>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                        {{ item.answers.length }} {{ $t('specialist') }}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div v-else>
                  <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                    {{ $t("Not answered") }}
                  </span>
                </div>
                <div>
                  <div class="text-left mt-1">
                    <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text" v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>
                    <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>
                    <div class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                      <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                      <span class="me-1 f12">{{ item.view }}</span>
                    </div>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </div>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <div class="d-flex justify-content-between">
        <div>
          <span class="16 font-weight-bold">{{
              $t("Latest posts")
          }}</span>
        </div>
        <div>
          <v-btn color="primary" depressed plain link min-width="auto" min-height="auto" height="auto" class="primary--text pa-1" :to="`/s/clinic/${$route.params.clinicId}/contents`">
            {{ $t('Show all') }}
          </v-btn>
        </div>
      </div>

      <v-row v-if="posts && posts.length > 0">
        <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(post, index) in posts" :key="index">
          <v-card :to="`/s/contents/${post.type.toLowerCase()}/${post.id}`" class="rounded-lg">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="post.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center">
              <div class="text-white w-100 text-center">
                <i class="fas fa-play p-4 bg-black-50 rounded-pill" v-if="post.type == 'VIDEO'"></i>
              </div>
            </v-img>

            <v-card-title class="f13 single-line pb-0">
              {{ post.title }}
            </v-card-title>

            <v-card-actions @click.prevent="openDrBottomSheet(post.publisher)" class="pt-0">
              <v-list-item two-line class="p-0">
                <v-list-item-avatar>
                  <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="post.publisherPic"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="f12 font-weight-bold primary--text">{{ post.publisherName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="f10">{{ post.createDate | moment("from", "now") }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>
      
    </v-container>

    <vue-bottom-sheet ref="callBottomSheet" :swipe-able="false">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <span class="text-center mb-2 d-block f15 font-weight-bold">{{ $t('Call') }}</span>
        <span class="text-center mb-2 d-block f13 primary--text">{{ clinic.contactDescription }}</span>

        <v-chip-group column v-if="clinic.phone">
        <v-chip
          v-for="phone in (clinic.phone || '').split(' , ')"
          class="my-1 ltr"
          color="primary"
          link
          :href="`tel:${ phone }`"
        >   
          <v-icon right>
            mdi-phone
          </v-icon>   
          {{phone}}
        </v-chip>
        </v-chip-group>

        <p class="f14 my-2 px-2" v-if="clinic.address" v-html="clinic.address"></p>

        <mapir v-if="clinic.lat && clinic.lon" :apiKey="mapirToken" :center="coordinates"
               :zoom="13" style="height:250px" class="rounded-xl overflow-hidden">
          <mapMarker
            :coordinates.sync="markerCoordinates"
            color="blue"
            :draggable="true"
          >
          </mapMarker>
        </mapir>

        <p class="f14 my-2 px-4 text-center border border-accent red--text text--darken-1 rounded-xl lh-22" v-text="$t('In critical situations, such as any child abuse, spousal abuse or suicidal thoughts, call 123 Social Emergency')"></p>

        <v-btn block color="primary" @click="closeCallBottomSheet" class="mb-2" rounded>{{ $t('Close') }}</v-btn>
      </section>
    </vue-bottom-sheet>

    <BshDoctor ref="drBottomSheet" :fromDoctorApp="true" />
  </div>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      coordinates: [51.420296, 35.732379],
      markerCoordinates: [51.420296, 35.732379],
      mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIn0.eyJhdWQiOiIxNzExNSIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIiwiaWF0IjoxNjQ1MjY3NTI5LCJuYmYiOjE2NDUyNjc1MjksImV4cCI6MTY0NzY4NjcyOSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.sw_1-g4cTn-J__N6nhChXOJVJNVUu8Spm-vemedB0_oz3hvCJTE4oxaCsCxCuRruUVspmgeGKgBgMh4-xtEhrwB_IVuIf5OrJSA1niTccaXGyr6k5VNpg_dHEPstIOJRzAWKsNyzlf2xUK0PkdulfI2AU0dtWFuuPtR12iZEQpZNEwoaOPD1A6tTb58HWBUoUltKSZk96QtoUlND6uuZKKTwaTYBPPwcL7v7JxDlxGEvSkoNWr-R8rzI48MAepXEBSgeewXIcOZ7-EQVHkbhjLyWu4GKJJo9RBqzEYYcVRewNDhqnAJIdPPyzsG7v5-DI2Mbmgo0lUDaD51XWpm2LA",

      clinic: {},
      questions: [],
      posts: [],

    };
  },
  methods: {
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    openCallBottomSheet() {
      this.$refs.callBottomSheet.open();

    },
    closeCallBottomSheet() {
      this.$refs.callBottomSheet.close();
    },
    getClinicDetails(clinicId) {
      apiService.getClinicDetails(clinicId)
      .then((response) => {
        this.clinic = response.data;
        this.coordinates = [this.clinic.lon, this.clinic.lat];
        this.markerCoordinates = [this.clinic.lon, this.clinic.lat];
      });
    },
    getClinicAnswerQuestion(clinicId) {
      apiService.getClinicAnswerQuestion(clinicId, 0, 1).then((response) => {
        this.questions = response.data;
      });
    },
    getClinicContents(clinicId) {
      apiService.getClinicContents(clinicId, 0, 4).then((response) => {
        this.posts = response.data;
      });
    },

  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if(this.$route.params.clinicId){
      this.getClinicDetails(this.$route.params.clinicId);
      this.getClinicAnswerQuestion(this.$route.params.clinicId);
      this.getClinicContents(this.$route.params.clinicId);
    }
    else {
      this.$router.go(-1);
    }


  },
};
</script>